import { createBlock } from '@lib/features/blocks/blocks';
import { Entry } from '@lib/features/entries';
import { useEffect } from 'react';
import EventCards from './EventCards';
import { useEvents } from './useEvents';

const EventCardsBlock = createBlock<'blocks_eventCards_BlockType'>(
  ({ heading, content, eventMultiple: maybeEvents }) => {
    const { trigger, items } = useEvents();

    useEffect(() => {
      if (maybeEvents?.length) return;
      trigger({ isEverydayEvent: false, limit: 4 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    return (
      <EventCards
        {...{
          heading,
          content,
          items: maybeEvents?.length ? (maybeEvents as Entry<'event'>[]) : items,
        }}
      />
    );
  }
);

export default EventCardsBlock;
